import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import _ from 'lodash';

export default abstract class Api {
    protected url: string;
    protected axios: AxiosInstance;
    private config?: AxiosRequestConfig;

    constructor(url: string) {
        this.url = url;
        this.axios = this.update_config({
            baseURL: url,
            timeout: 15000,
            headers: {
                'Content-Type': "application/json"
            }
        }, true);
    }

    update_config = (config: AxiosRequestConfig, override = false) => {
        if (override) {
            //overrides the config
            this.config = config;

            return this.axios = axios.create(config);
        }

        if (!this.config) {
            //put for safe keeping ...
            this.config = config;
        }

        // const newConfig = Object.assign({}, this.config, config);
        const newConfig = _.merge(this.config, config);

        this.config = newConfig;
        return this.axios = axios.create(newConfig);
    }
}