import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function PopupSimple(props: { show: boolean; title: string; onClose: () => void; children?: React.ReactNode; disabled?: boolean, size?: 'sm' | 'lg' | 'xl'; }) {
    return (
        <>
            <Modal show={props.show} onHide={props.onClose} size={props.size}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose} disabled={props.disabled}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
